<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
       strModel="Configuracoes/ContaBancaria"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getContaEmail"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getContaEmail();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Titular",
          sortable: true,
          value: "strTitutar",
        },
        {
          text: "Conta",
          sortable: true,
          value: "strConta",
        },
        {
          text: "Padrão",
          sortable: true,
          value: "boolPadrao",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{}
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "configuracoes.contaBancaria.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "configuracoes.contaBancaria.edit",
        params: { intId: data.intId },
      });
    },

    getContaEmail() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get(
          "Configuracoes/ContaBancaria",
          this.formFiltros
        )
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {

              let strBanco = '<img src="'+objModel.strImagem+'"> ';

           
              let item = {
                intId: objModel.intId,
                intNumeroBanco: objModel.intNumeroBanco,
                strNomeTitular: objModel.strNomeTitular,
                strDocumentoTitular: objModel.strDocumentoTitular,
                strTitutar:objModel.strNomeTitular+' <br>'+objModel.strDocumentoTitular,
                strConta: strBanco+objModel.intNumeroAgencia+' / '+objModel.intNumeroConta,
                boolPadrao: this.$utilidade.parseBoolean(objModel.boolPadrao),
                _item: objModel,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
